import request from '@/utils/http.js'

/**
 * 登录
 * @param {object} data 
 * @returns 
 */
export const loginByUserName = data => {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 手机验证码登录
export const loginByPhone = data => {
  return request({
    url: '/login/sms',
    method: 'post',
    data
  })
}

// 获取验证码
export const getSmsCode = data => {
  return request({
    url: '/sms/smscode',
    method: 'get',
    params: data
  })
}




// 获取登录用户信息
export function getUserInfo() {
  return request({
    url: '/userInfo',
    method: 'get'
  })
}
